
import { Flex, Text} from 'theme-ui'
import { Fragment } from "react";
import glossary from "app/data/planner/glossary.json";

import ReactTooltip from 'react-tooltip'

export default function AutoToolTip({children, color}) {
    let words = []
    if (Array.isArray(children)) {
        const text_block = children.join(" ")
        words = text_block.split(" ")
    }
    else if (children.split) {
        words = children.split(" ")
    }

    const newChildren = words.map((word, index) => {
        const key = `${word}-${index}`
        const baseWord = word.toLowerCase().replace(/[^a-z]/, '')
        if (glossary.words[baseWord]) {
            return (
                <Fragment key={key}>
                    <Text data-tip data-for={key} variant="highlight">
                        {word}&nbsp;
                    </Text>
                    <ReactTooltip id={key} aria-haspopup="true" backgroundColor="#ffd327">
                        <Text sx={{p: 2, fontSize: 2, color: 'text'}}>
                            {glossary.words[baseWord]}
                        </Text>
                    </ReactTooltip>
                </Fragment>
            )
        }
        return <Text key={key}>{word}&nbsp;</Text>
    })
    return (
        <Flex
            sx={{
                flexWrap: 'wrap'
            }}
        >
            {newChildren}
        </Flex>
    )
}
