import { Flex, Heading, Text } from "theme-ui";
import { useContext } from "react";
import { Footer, Page, LinkHome, LoadSampleButton, PrintButton, StartButton } from "components";
import Step1Block from "./step1/Step1Block";
import Step2Block from "./step2/Step2Block";
import Step3Block from "./step3/Step3Block";
import Step4Block from "./step4/Step4Block";
import Step5Block from "./step5/Step5Block";
import Step6Block from "./step6/Step6Block";
import { PlannerContext } from "app/contexts/PlannerContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";

export default function FrontPage() {
  const { clearAnswers, setTestAnswers } = useContext(PlannerContext);
  const navigate = useNavigate();
  const gotoPrint = () => {
    navigate("/planner/print");
  };
  return (
    <Page>
      <Heading as="h1"><FontAwesomeIcon icon={faProjectDiagram}/> VentureLab Business&nbsp;Planner</Heading>
      <LinkHome/>
      <Text sx={{ my: 4 }}>
        You can use this to plan your business. Fill in your answers to each question below.
      </Text>
      <Flex sx={{ flexDirection: ["column", "column", "row"], alignItems: 'center' }}>
        <Step1Block />
        <Step2Block />
        <Step3Block />
      </Flex>
      <Flex sx={{ flexDirection: ["column", "column", "row"] }}>
        <Step4Block />
        <Step5Block />
      </Flex>
      <Flex sx={{ flexDirection: ["column", "column", "row"] }}>
        <Step6Block />
      </Flex>

      <Flex>
        <PrintButton title={"Print Your Plan"} onClick={gotoPrint}/>
        <StartButton title={"Start a New Plan"} onClick={clearAnswers}/>
        <LoadSampleButton title={'Load Sample Answers'} onClick={setTestAnswers}/>
      </Flex>
      <Footer />
    </Page>
  );
}
