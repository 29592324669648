import React from 'react'
import { Box } from 'theme-ui'
import logo from '../assets/images/VentureLabLogo_280x90px.png'

export default function Logo() {
  return (
    <Box my={4}>
      <img src={logo} alt="logo" />
    </Box>
  )
}
