import React from "react";
import HomePage from "pages/HomePage";
import Planner from "pages/planner";
import Pitch from "pages/pitch";
import { PlannerContextProvider } from "app/contexts/PlannerContext";
import { PitchContextProvider } from "app/contexts/PitchContext";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import theme from "design-system/theme";


function App() {
  return (
    <PitchContextProvider>
      <PlannerContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/planner/*" element={<Planner />} />

              <Route path="/pitch/*" element={<Pitch />} />
              <Route path="*" element={<HomePage />} />

            </Routes>
          </Router>
        </ThemeProvider>
      </PlannerContextProvider>
    </PitchContextProvider>

  );
}


export default App;
