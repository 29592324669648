import { Box, Card, Heading, Text } from 'theme-ui'
import { PitchContext } from 'app/contexts/PitchContext'

import { useContext, Fragment } from 'react'

export default function PitchBlock() {
  const { answers, questions, getAfterText, getBeforeText } =
    useContext(PitchContext)

  return (
    <Card
      variant="pitch"
      sx={{
        display: 'block',
        minHeight: 100,
        px: 24,
        position: 'sticky',
        top: 10,
        maxHeight: '75vh',
        overflowY: 'auto'
      }}
    >
      <Heading variant="topic_done" color={'royal_blue'}>
        The Pitch
      </Heading>

      <Box color="royal_blue" sx={{ fontSize: 4 }} m={4}>
        {Array.from(questions).map(([name, data]) => {
          if (data.type !== 'madLib') {
            return null
          }
          const beforeText = getBeforeText(data)
          const afterText = getAfterText(data)
          if (!answers.get(name) || answers.get(name) === '') {
            return null
          }

          return (
            <Text key={name}>
              <Fragment key={name}>
                {beforeText}
                <Text sx={{ fontWeight: 800 }}> {answers.get(name)}</Text>
                {afterText}
              </Fragment>{' '}
            </Text>
          )
        })}
      </Box>
    </Card>
  )
}
