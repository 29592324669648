/** @jsxImportSource theme-ui */
import { Button, Flex,  Text } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export default function AddRowButton({ onClick }) {
  return (
    <tr>
      <td colSpan="4">
        <Flex sx={{ my: 2, alignItems: 'center' }} onClick={onClick}>
          <Button variant="icon">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Text sx={{ fontWeight: 'bold', color: 'curious_blue', mx: 2 }}>
            Add another item
          </Text>
        </Flex>
      </td>
    </tr>
  )
}
