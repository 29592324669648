import React, { useState, useEffect } from 'react'
import step1Data from 'app/data/pitch/step1.json'

const PitchContext = React.createContext()
const LS_ANSWER_KEY = 'ventureLabPitchAnswers'

const PitchContextProvider = (props) => {
  const [answers, setAnswers] = useState(new Map())
  const [questions, setQuestions] = useState(new Map())
  const [isLoaded, setIsLoaded] = useState(false)

  const [openItem, setOpenItem] = useState(null)

  useEffect(() => {
    if (!isLoaded) {
      const newQuestions = new Map()
      step1Data.questions.forEach((q) => {
        newQuestions.set(q.name, q)
      })
      setQuestions(newQuestions)
      const lsAnswers = localStorage.getItem(LS_ANSWER_KEY)
      if (lsAnswers) {
        setAnswers(new Map(JSON.parse(lsAnswers)))
      }
      setIsLoaded(true)
    }
  }, [isLoaded])

  const clearAnswers = () => {
    localStorage.setItem(LS_ANSWER_KEY, null)
    setAnswers(new Map())
    setQuestions(new Map())
    setIsLoaded(false)
  }
  const setAnswer = (questionName, answer) => {
    const newAnswers = new Map(answers).set(questionName, answer)
    storeAnswers(newAnswers)
  }

  const storeAnswers = (newAnswers) => {
    //    console.log("Stroing? ", newAnswers)
    localStorage.setItem(
      LS_ANSWER_KEY,
      JSON.stringify(Array.from(newAnswers.entries()))
    )
    setAnswers(newAnswers)
  }

  const offerType = answers.get('offerType')
    ? answers.get('offerType')
    : 'product'

  //  console.log("Step", stepsState.get(1)?.currentQuestion, stepsState.get(1)?.isDone)
  // console.log("Answers", answers)
  const isSingular = () => {
    const myself = answers.get('groupType')
      ? answers.get('groupType')
      : 'myself'
    return myself === 'myself'
  }
  const getBeforeText = (data) => {
    if (isSingular()) {
      return data[offerType].singularBeforeText || data[offerType].beforeText
    } else {
      return data[offerType].pluralBeforeText || data[offerType].beforeText
    }
  }
  const getAfterText = (data) => {
    if (isSingular()) {
      return data[offerType].singularAfterText || data[offerType].afterText
    } else {
      return data[offerType].pluralAfterText || data[offerType].afterText
    }
  }
  return (
    <PitchContext.Provider
      value={{
        isLoaded,
        offerType,
        setAnswer,
        openItem,
        setOpenItem,
        answers,
        questions,
        clearAnswers: clearAnswers,
        isSingular,
        getAfterText,
        getBeforeText,
        setTestAnswers: () => {
          clearAnswers()
          setIsLoaded(false)
          let newAnswers = new Map()
          questions.forEach((q) => {
            newAnswers.set(q.name, q.testAnswer)
          })
          storeAnswers(newAnswers)
        },

        getPitchText: () => {
          return Array.from(questions)
            .map(([name, data]) => {
              if (data.type !== 'madLib') {
                return null
              }
              const beforeText = getBeforeText(data)
              const afterText = getAfterText(data)

              if (!answers.get(name) || answers.get(name) === '') {
                return null
              }

              return [beforeText, answers.get(name), afterText].join(' ').trim()
            })
            .filter((e) => e)
            .join(' ')
        }
      }}
    >
      {props.children}
    </PitchContext.Provider>
  )
}

export { PitchContext, PitchContextProvider }
