
import {  Card, Heading, Text } from 'theme-ui'
import { PlannerContext } from 'app/contexts/PlannerContext'
import { AutoToolTip } from 'components'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Step2Block() {
  const navigate = useNavigate()

  const { getAnswers, wipeDone } = useContext(PlannerContext)
  const goToStep = () => {
    wipeDone(2);
    navigate("/planner/step2");
  }
  const answers = getAnswers(2)
  const answers1 = getAnswers(1)
  if (answers.offerCustomer) {
    return (
      <Card variant="done" sx={{m:3}} onClick={goToStep}>
        <Heading variant="step_done">Step #2</Heading>

        <Heading variant="topic_done">
          I will sell to {answers.offerCustomer}.
        </Heading>

        <Text color="curious_blue" my={4}>
          They will purchase my{' '}
          {answers1.offerType.toLowerCase()} for {answers.offerPrice}. I distribute my{' '}
          {answers1.offerType.toLowerCase()} {answers.offerWhere}.
        </Text>
      </Card>
    )
  }
  return (
    <Card variant="empty" onClick={goToStep}>
      <Heading variant="step_empty">Step #2</Heading>

      <Heading variant="topic_empty">Who is going to buy from you?</Heading>

      <Text my={4}>
        <AutoToolTip>
          If you understand your customers wants and needs, they will be more likely to purchase what you sell. How much money will this cost them? Where will you sell your {answers1.offerType || "product"}?
        </AutoToolTip>
      </Text>
    </Card>
  )
}
