/* eslint-disable  react/jsx-pascal-case */
import React, { useRef } from 'react'
import {  Box, Flex, Heading, Themed, Text } from 'theme-ui'
import { useContext } from 'react'
import { BackButton ,Footer, Page, PrintButton } from 'components'
import { useReactToPrint } from 'react-to-print'
import { PlannerContext } from 'app/contexts/PlannerContext'
import ColorBand from 'assets/images/Color-band.png'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

export default function PrintPage() {
  const componentRef = useRef()
  const navigate = useNavigate()
  const { getAnswers, getRows, getTotals, calculateProfit } = useContext(
    PlannerContext
  )
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  //DJE - need to handle print style for
  //hiding the buttons and handling the page breaks
  return (
    <Box my={4}>
      <Flex>
      <PrintButton title={'Print This'} onClick={handlePrint}/>
        <BackButton title={'Go Back'}           onClick={() => {
          navigate("/planner/home");
        }}/>
      </Flex>
      <PrintableForm
        ref={componentRef}
        getAnswers={getAnswers}
        getRows={getRows}
        getTotals={getTotals}
        calculateProfit={calculateProfit}
      />
    </Box>
  )
}

class PrintableForm extends React.Component {
  render() {
    const { getRows, getTotals, getAnswers, calculateProfit } = this.props
    const expenseRows = getRows(4)
    const revenueRows = getRows(5)
    let expenseHeaders = []
    let revenueHeaders = []
    const displayRows = []
    if (expenseRows) {
      expenseHeaders = Object.keys(expenseRows[0].cells)
    }

    if (revenueRows) {
      revenueHeaders = Object.keys(revenueRows[0].cells)

      revenueRows.forEach((row, index) => {
        let displayRow = []
        if (displayRows[index]) {
          displayRow = displayRows[index]
        } else {
          ;[...Array(expenseHeaders.length + 1).keys()].map((i) =>
            displayRow.push(null)
          )
        }

        revenueHeaders.forEach((header) => {
          displayRow.push(row.cells[header])
        })

        displayRows[index] = displayRow
      })
    }

    const step1Answers = getAnswers(1)
    const step2Answers = getAnswers(2)
    const step3Answers = getAnswers(3)

    //  console.log("Answers", step3Answers)
    //    console.log("Answers", getAnswers(2))
    //  console.log("Answers", getAnswers(3))
    const profitValues = calculateProfit(
      getTotals(4)?.numeric,
      getTotals(5)?.numeric
    )
    return (
      <Page>
        <Themed.table>
          <tbody>
            <Themed.tr>
              <Themed.td
                colSpan="3"
                sx={{ textAlign: 'right', borderColor: 'transparent' }}
              >
                <Text sx={{ my: 2, mr: 350 }}> By: </Text>
              </Themed.td>
            </Themed.tr>
            <Themed.tr>
              <Themed.td colSpan="3" sx={{ textAlign: 'center' }}>
                <Heading><FontAwesomeIcon icon={faProjectDiagram}/> VentureLab Business Model Summary </Heading>
                <img width="100%" src={ColorBand} alt="color band" />
              </Themed.td>
            </Themed.tr>
            <Themed.tr>
              <Themed.td sx={{ border: '1px solid', borderColor: 'gray' }}>
                <Text variant="print_label">{step1Answers.offerType}</Text>
                <Text variant="print_answer">
                  {step1Answers.offerName} - {step1Answers.bigIdea}
                </Text>
                <Text variant="print_answer"> {step1Answers.offerDetails}</Text>
              </Themed.td>
              <Themed.td
                colSpan="2"
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">Target Market or Customer</Text>
                <Text variant="print_description">
                  The people who buy this are mostly...
                </Text>
                <Text variant="print_answer">{step2Answers.offerCustomer}</Text>
              </Themed.td>
            </Themed.tr>
            <Themed.tr>
              <Themed.td
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">Competitive Advantage</Text>
                <Text variant="print_description">
                  This is different from other offerings because...
                </Text>
                <Text variant="print_answer">
                  {step1Answers.offerDifference}
                </Text>
                <Text variant="print_answer">{step1Answers.offerWhy}</Text>
              </Themed.td>
              <Themed.td
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">Distribution Channel</Text>{' '}
                <Text variant="print_description">This is sold...</Text>
                <Text variant="print_answer">{step2Answers.offerWhere}</Text>
                <Text variant="print_answer">{step2Answers.offerPrice}</Text>
              </Themed.td>
              <Themed.td
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">Resources</Text>{' '}
                <Text variant="print_description">
                  Things needed to make and deliver this [ product | service ]
                  are…
                </Text>
                <Text variant="print_answer">{step3Answers.offerExpenses}</Text>
              </Themed.td>
            </Themed.tr>
            <Themed.tr sx={{ borderColor: 'gray' }}>
              <Themed.td
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">Expenses</Text>
                <Text variant="print_description">
                  I will spend money on...
                </Text>
                <Themed.table>
                  <thead>
                    <Themed.tr>
                      {expenseHeaders.map((header) => {
                        return (
                          <Themed.th
                            sx={{
                              border: '1px solid',
                              borderColor: 'gray',
                              bg: 'curious_blue',
                              color: 'white',
                              p: 1
                            }}
                            key={header}
                          >
                            {header}
                          </Themed.th>
                        )
                      })}
                    </Themed.tr>
                  </thead>
                  <tbody>
                    {(expenseRows || []).map((row) => {
                      return (
                        <Themed.tr
                          key={row.id}
                          sx={{
                            display:
                              row.cells['Item Name']['value']['raw'].length > 0
                                ? 'table-row'
                                : 'none'
                          }}
                        >
                          {expenseHeaders.map((header) => (
                            <Themed.td
                              sx={{
                                border: '1px solid',
                                borderColor: 'gray',
                                p: 1
                              }}
                              key={header}
                            >
                              {row.cells[header].value.text}
                            </Themed.td>
                          ))}
                        </Themed.tr>
                      )
                    })}
                    <Themed.tr>
                      <Themed.td colSpan="4" sx={{ textAlign: 'right' }}>
                        <Text p={2}>
                          Total Expenses{' '}
                          <b>{profitValues.get('EXPENSES').text}</b>
                        </Text>
                      </Themed.td>
                    </Themed.tr>
                  </tbody>
                </Themed.table>
              </Themed.td>
              <Themed.td
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">Revenue</Text>
                <Text variant="print_description">I will make money by...</Text>
                <Text variant="print_answer">{step3Answers.offerRevenue}</Text>
                <Themed.table>
                  <tbody>
                    <Themed.tr>
                      {revenueHeaders.map((header) => {
                        return (
                          <Themed.th
                            sx={{
                              border: '1px solid',
                              borderColor: 'gray',
                              bg: 'curious_blue',
                              color: 'white',
                              p: 1
                            }}
                            key={header}
                          >
                            {header}
                          </Themed.th>
                        )
                      })}
                    </Themed.tr>
                    {(revenueRows || []).map((row) => {
                      return (
                        <Themed.tr
                          key={row.id}
                          sx={{
                            display:
                              row.cells['Item Name']['value']['raw'].length > 0
                                ? 'table-row'
                                : 'none'
                          }}
                        >
                          {revenueHeaders.map((header) => (
                            <Themed.td
                              sx={{
                                border: '1px solid',
                                borderColor: 'gray',
                                p: 1
                              }}
                              key={header}
                            >
                              {row.cells[header].value.text}
                            </Themed.td>
                          ))}
                        </Themed.tr>
                      )
                    })}
                    <Themed.tr>
                      <Themed.td colSpan="4" sx={{ textAlign: 'right' }}>
                        <Text p={2}>
                          Total Revenue{' '}
                          <b>{profitValues.get('REVENUE').text}</b>
                        </Text>
                      </Themed.td>
                    </Themed.tr>
                  </tbody>
                </Themed.table>
              </Themed.td>
              <Themed.td
                sx={{
                  verticalAlign: 'top',
                  border: '1px solid',
                  borderColor: 'gray'
                }}
              >
                <Text variant="print_label">
                  Profit (Revenue - Expenses = Profit)
                </Text>
                <Text variant="print_description">
                  I will spend my profit on...
                </Text>
                <Text variant="print_answer">{step3Answers.offerProfit}</Text>
                <Text variant="print_answer">
                  Total Profit ~ <b>{profitValues.get('TOTAL').text}</b>
                </Text>
              </Themed.td>
            </Themed.tr>
          </tbody>
        </Themed.table>

        <Footer />
      </Page>
    )
  }
}
