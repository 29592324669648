/** @jsxImportSource theme-ui */
import {  Heading } from 'theme-ui'

import { Link } from 'react-router-dom'

export default function SectionHeader({ destination }) {
  return (
    <Link sx={{ textDecoration: 'none' }} to={destination}>
      <Heading as="h2" my={4}>
        VentureLab Business Builder
      </Heading>
    </Link>
  )
}
