import {  Button } from 'theme-ui'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight} from "@fortawesome/free-solid-svg-icons";

export default function QuestionNextButton({ disabled, onClick }) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant={disabled ? 'disabled' : 'next'}
    >
      Next <FontAwesomeIcon icon={faArrowRight}/>
    </Button>
  )
}
