import { useContext, Fragment } from 'react'
import { Flex, Heading, Box, Text } from 'theme-ui'
import { AutoToolTip } from 'components'
import { PitchContext } from 'app/contexts/PitchContext'
import { Document, Packer, Paragraph, TextRun } from 'docx'

import { saveAs } from 'file-saver'
import PitchBlock from './PitchBlock'
import MadLibInput from './MadLibInput'
import QuestionChooser from './QuestionChooser'
import {
  Page,
  Footer,
  LinkHome,
  PrintButton,
  StartButton,
  LoadSampleButton,
  DownloadDocButton,
  DownloadTextButton
} from 'components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'

/*
Allow them to add a new sentence into the paragraph.
Tab to be able to open the form parts - https://www.npmjs.com/package/react-use-keypress
*/

export default function FrontPage() {
  const {
    isLoaded,
    offerType,
    clearAnswers,
    setTestAnswers,
    questions,
    answers,
    getPitchText,
    getAfterText,
    getBeforeText
  } = useContext(PitchContext)
  const navigate = useNavigate()

  const gotoPrint = () => {
    navigate('/pitch/print')
  }

  if (!isLoaded) {
    return null
  }
  const downloadTxtFile = () => {
    const element = document.createElement('a')
    const file = new Blob(
      [getPitchText()],

      { type: 'text/plain;charset=utf-8' }
    )
    element.href = URL.createObjectURL(file)
    element.download = 'myPitch.txt'
    document.body.appendChild(element)
    element.click()
  }

  const downloadDocFile = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [new TextRun(getPitchText())]
            })
          ]
        }
      ]
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'MyPitch.docx')
    })
  }
  return (
    <Page>
      <Heading as="h1">
        <FontAwesomeIcon icon={faMicrophone} /> VentureLab Pitch&nbsp;Builder
      </Heading>
      <LinkHome />
      <PitchBlock />
      <Box sx={{ my: 3, fontSize: 3 }}>
        {Array.from(questions).map(([name, data]) => {
          if (data.type === "choice") {
            return (
              <QuestionChooser
                key={name}
                question={data}
                answer={answers.get(name)}
              />
            );
          }
          const beforeText = getBeforeText(data);
          const afterText = getAfterText(data);
          const explanationText = data[offerType].explanation;

          // const explanationText = data.explanation.replace(
          //   '[ product | service ]',
          //   data[offerType]
          // )
          return (
            <Fragment key={name} sx={{ my: 3 }}>
              {explanationText ? (
                <Text variant={"explanation"}>
                  <AutoToolTip>{explanationText}</AutoToolTip>
                </Text>
              ) : null}
              <Flex variant={'text.mad_sentence'}>
                <Text> {beforeText} </Text>
                <MadLibInput
                  answer={answers.get(name)}
                  name={name}
                  placeHolder={data.placeHolder}
                  hint={data.hint}
                />
                <Text> {afterText} </Text>
              </Flex>
            </Fragment>
          )
        })}
      </Box>

      <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
        <PrintButton title={'Print Your Pitch'} onClick={gotoPrint} />
        <DownloadTextButton
          title={'Download as Text'}
          onClick={downloadTxtFile}
        />
        <DownloadDocButton
          title={'Download as Word Doc'}
          onClick={downloadDocFile}
        />
        <StartButton title={'Start a New Pitch'} onClick={clearAnswers} />
        <LoadSampleButton
          title={'Load Sample Answers'}
          onClick={setTestAnswers}
        />
      </Flex>
      <Footer />
    </Page>
  )
}
