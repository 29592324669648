import React, { useContext } from 'react'

import { PlannerContext } from 'app/contexts/PlannerContext'
import { StepContextProvider } from 'app/contexts/StepContext'
import { SheetContextProvider } from './SheetContext'
import { convertChildrenToConfig } from './utils'
import MainSheet from './MainSheet'

export default function Sheet({
  step,
  children,
  defaultRows,
  allowAdd,
  allowRemove,
  allowReorder
}) {
  const { getRows, setRows, getTotals, setTotals, isLoaded } = useContext(PlannerContext)

  const config = convertChildrenToConfig(children)

  config.set('allowAdd', !!allowAdd)
  config.set('allowRemove', !!allowRemove)
  config.set('allowReorder', !!allowReorder)
  config.set('defaultRows', defaultRows || 1)
  config.set('step', step)
  // console.log("Config", config)

  return (
    <StepContextProvider step={step}>
      <SheetContextProvider
        config={config}
        setRows={setRows}
        getRows={getRows}
        setTotals={setTotals}
        getTotals={getTotals}
        plannerLoaded={isLoaded}
      >
        <MainSheet />
      </SheetContextProvider>
    </StepContextProvider>
  )
}
