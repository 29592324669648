import { Flex, Text } from 'theme-ui'
import Logo from './Logo'

export default function Footer({ children }) {
  const year = new Date().getFullYear()
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row', 'row'],
        alignItems: 'center',
        justifyContent: ['center', 'space-between', 'space-between']
      }}
    >
      <Text sx={{ my: 3 }}> VentureLab &copy;{year}</Text>
      <Text color="silver" my={3}>
        version 1.7.2
      </Text>
      <Logo />
    </Flex>
  )
}
