
import {  Card, Heading, Text } from 'theme-ui'
import { PlannerContext } from 'app/contexts/PlannerContext'
import { AutoToolTip } from 'components'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Step2Block() {
  const navigate = useNavigate()

  const { getAnswers, wipeDone } = useContext(PlannerContext)
  const goToStep = () => {
    wipeDone(3);
    navigate("/planner/step3");
  }
  const answers = getAnswers(3)
  if (answers.offerExpenses) {
    return (
      <Card variant="done" sx={{m:3}} onClick={goToStep}>
        <Heading variant="step_done">Step #3</Heading>

        <Heading variant="topic_done">
          I will make money by {answers.offerRevenue}
        </Heading>

        <Text color="curious_blue" my={4}>
          My expenses are {answers.offerExpenses}. I will use my profit to {answers.offerProfit}
        </Text>
      </Card>
    )
  }
  return (
    <Card variant="empty" onClick={goToStep}>
      <Heading variant="step_empty">Step #3</Heading>
      <Heading variant="topic_empty">How will you spend and make money?</Heading>
      <Text my={4}>
        <AutoToolTip>
          You want to make more money than you spend. That means keeping expenses low and revenue high to make profit!
        </AutoToolTip>
      </Text>
    </Card>
  )
}
