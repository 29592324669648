
import {  Card, Heading, Text } from 'theme-ui'
import { PlannerContext } from 'app/contexts/PlannerContext'
import { AutoToolTip } from 'components'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Step1Block() {
  const navigate = useNavigate()

  const { getAnswers, wipeDone } = useContext(PlannerContext)
  const goToStep = () => {
    wipeDone(1);
    navigate("/planner/step1");
  }
  const answers = getAnswers(1)
  if (answers.offerName) {
    return (
      <Card variant="done" onClick={goToStep}>
        <Heading variant="step_done">Step #1</Heading>

        <Heading variant="topic_done">
          My BIG IDEA is {answers.offerName}.
        </Heading>

        <Text color="curious_blue" my={4}>
          My {answers.offerType.toLowerCase()} idea is to {answers.offerDetails}.{' '}
          {answers.offerDifference} {answers.offerWhy}
        </Text>
      </Card>
    )
  }
  return (
    <Card variant="empty" onClick={goToStep}>
      <Heading variant="step_empty">Step #1</Heading>

      <Heading variant="topic_empty">What's your BIG IDEA?</Heading>

      <Text my={4}>
        <AutoToolTip>
          Every business starts with a great idea. What product do you want to
          make? What service do you want to offer? What's your advantage?
        </AutoToolTip>
      </Text>
    </Card>
  )
}
