import React, {useEffect} from 'react'
import shortid from 'shortid'
import {formatCurrency, formatNumber} from './utils'

const SheetContext = React.createContext()

function headersToRow(headerList, headersObj) {
    const rowId = shortid.generate()
    const row = {id: rowId, cells: {}}
    headerList.forEach((label) => {
        const type = headersObj[label].type
        let value = {raw: '', text: '', numeric: 0}
        row.cells[label] = {
            rowId,
            label: label,
            value: value,
            type: type
        }
    })
    return row
}

function initRows(config, headerOrder, headersObj) {
    return [...Array(config.get('defaultRows')).keys()].map((idx) => {
        return headersToRow(headerOrder, headersObj)
    })
}


const SheetContextProvider = ({
                                  children,
                                  config,
                                  getRows,
                                  setRows,
                                  setTotals,
                                  getTotals,
                                  plannerLoaded
                              }) => {

    const step = config.get('step')

    const headerOrder = config.get('headers').map((header) => header.label)
    const headersObj = Object.fromEntries(
        new Map(config.get('headers').map((header) => [header.label, header]))
    )
    const rows = getRows(step)

    useEffect(() => {
        if (plannerLoaded ) {
            if (typeof rows === "undefined") {
                const newRows = initRows(config, headerOrder, headersObj)
                setRows(step, newRows)
            }

        }
    }, [rows, plannerLoaded, step, config, headerOrder, headersObj, setRows])


    const reorderRows = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }
    const getRowById = (rowId) => {
        return rows.find((row) => row.id === rowId)
    }
    const getRowIndexById = (rowId) => {
        return rows.findIndex((row) => row.id === rowId)
    }
    const updateRow = (rowId, cell) => {
        const newRow = Object.assign({}, getRowById(rowId))
        newRow.cells[cell.label] = cell
        const newRows = rebuildRow(newRow, rowId)
        rebuildRows(newRows)
    }
    const rebuildRow = (newRow, rowId) => {
        const newRows = [...rows]
        newRows[getRowIndexById(rowId)] = updateRowTotal(newRow)
        return newRows

    }
    const rebuildRows = (newRows) => {
        updateTotal(newRows)
        setRows(step, newRows)
    }

    const updateTotal = (rows) => {
        const source =
            config.get('total').source ||
            (() => {
                return {type: 'MONEY', numeric: 0}
            })
        const newTotal = source(rows)
        setTotals(step, processTotal(newTotal))
    }
    const updateRowTotal = (row) => {
        const sum = Object.keys(row.cells)
            .map((key) => [row.cells[key].label, row.cells[key].type])
            .filter(([k, type]) => type === 'SUM')
        if (sum.length > 1) {
            throw Error('This system only supports a single sum in a row')
        }
        if (sum.length === 0) {
            return row
        }
        const sumKey = sum[0][0]

        const newTotal = headersObj[sumKey].source(row)
        row.cells[sumKey].value = processTotal(newTotal)

        return row
    }

    const processTotal = (newTotal) => {
        if (newTotal.type === 'MONEY') {
            return {
                raw: `${newTotal.numeric}`,
                text: formatCurrency(newTotal.numeric / 100),
                numeric: newTotal.numeric
            }
        } else {
            return {
                raw: `${newTotal.numeric}`,
                text: formatNumber(newTotal.numeric),
                numeric: newTotal.numeric
            }
        }
    }

    return (
        <SheetContext.Provider
            value={{
                updateRow,
                addBlankRow: () => {
                    const newRow = headersToRow(headerOrder, headersObj)
                    rows.push(newRow)
                    setRows(step, rows)

                },
                removeRow: (rowId) => {
                    if (rows?.length < 2) {
                        return null
                    }

                    const idx = getRowIndexById(rowId)
                    if (idx > -1) {
                        rows.splice(idx, 1)
                        rebuildRows(rows)
                      //  setRows(step, rows)

                    }
                },
                headerOrder,
                step: step,
                rows: rows || [],
                total: getTotals(step) || {value: 0, numeric: 0, text: '$0.00'},
                allowAdd: config.get('allowAdd'),
                allowRemove: config.get('allowRemove') && rows?.length > 1,
                allowReorder: config.get('allowReorder'),
                totalConfig: config.get('total'),
                headerConfig: config.get('headers'),
                showTotal: !!config.get('total'),
                onDragEnd: (result) => {
                    if (
                        !result.destination ||
                        result.destination.index === result.source.index
                    ) {
                        return
                    }

                    const newRows = reorderRows(
                        rows,
                        result.source.index,
                        result.destination.index
                    )

                    setRows(step, newRows)

                },
                isTotalBefore: config.get('total')?.location === 'BEFORE'
            }}
        >
            {children}
        </SheetContext.Provider>
    )
}

export {SheetContext, SheetContextProvider}
