import React from "react";
import FrontPage from "./FrontPage";


import { StepContextProvider } from "app/contexts/StepContext";

import {

  Routes,
  Route
} from "react-router-dom";


import Step1Page from "./step1/Step1Page";
import Step2Page from "./step2/Step2Page";
import Step3Page from "./step3/Step3Page";
import PrintPage from "./PrintPage";

function Planner() {
  return (
    <Routes>
      <Route path="/step1" element={<StepContextProvider step={1}>
        <Step1Page />
      </StepContextProvider>} />
      <Route path="/step2" element={<StepContextProvider step={2}>
        <Step2Page />
      </StepContextProvider>} />
      <Route path="/step3" element={<StepContextProvider step={3}>
        <Step3Page />
      </StepContextProvider>} />
      <Route path="/print" element={<PrintPage />} />

      <Route path="*" element={<FrontPage />} />
    </Routes>
  );
}


export default Planner;
