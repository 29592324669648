/* eslint-disable  react/jsx-pascal-case */

import React, { useRef } from "react";
import { Box, Flex, Heading, Themed, Text } from "theme-ui";
import { useContext } from "react";
import { BackButton, Footer, Page, PrintButton } from "components";
import { useReactToPrint } from "react-to-print";
import { PitchContext } from "app/contexts/PitchContext";
import ColorBand from "assets/images/Color-band.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";

export default function PrintPage() {
  const componentRef = useRef();
  const navigate = useNavigate();
  const { getPitchText } = useContext(
    PitchContext
  );
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  //DJE - need to handle print style for
  //hiding the buttons and handling the page breaks
  return (
    <Box my={4}>
      <Flex>
        <PrintButton title={"Print This Page"} onClick={handlePrint} />
        <BackButton title={"Go Back"} onClick={() => {
          navigate("/pitch/home");
        }} />
      </Flex>
      <PrintableForm
        ref={componentRef}
        pitchText={getPitchText()}

      />
    </Box>
  );
}

class PrintableForm extends React.Component {
  render() {
    const { pitchText } = this.props;


    return (
      <Page>
        <Themed.table>
          <tbody>
          <Themed.tr>
            <Themed.td
              colSpan="3"
              sx={{ textAlign: "right", borderColor: "transparent" }}
            >
              <Text sx={{ my: 2, mr: 350 }}> By: </Text>
            </Themed.td>
          </Themed.tr>
          <Themed.tr>
            <Themed.td colSpan="3" sx={{ textAlign: "center" }}>
              <Heading><FontAwesomeIcon icon={faMicrophone}/> VentureLab Pitch </Heading>
              <img width="100%" src={ColorBand} alt="color band" />
            </Themed.td>
          </Themed.tr>
          <Themed.tr>
            <Themed.td sx={{ border: "1px solid", borderColor: "gray", p:4 }}>
              <Text variant="print_label">
                {pitchText}
              </Text>
            </Themed.td>
          </Themed.tr>

          </tbody>
        </Themed.table>

        <Footer />
      </Page>
    );
  }
}
