import { Card, Flex, Heading, Text } from "theme-ui";

import { Footer, Page } from "components";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMicrophone, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";

export default function FrontPage() {
  const navigate = useNavigate();

  return (
    <Page>
      <Heading as="h1"><FontAwesomeIcon icon={faHome}/> Welcome to the VentureLab Business Model Builder</Heading>
      <Text
        sx={{
          my: 4
        }}
      >
        You can use this to plan a business and create a pitch.
      </Text>
      <Flex sx={{ flexDirection: ["column", "column", "row"] }}>
        <Card variant="done" sx={{ m: 3, minWidth:5}} onClick={() => {
          navigate("/planner/home");
        }}>
          <Heading variant="topic_done"><FontAwesomeIcon icon={faProjectDiagram}/> Business Planner </Heading>

          <Text color="curious_blue" my={4}>
            I want to work on my business plan.
          </Text>
        </Card>
        <Card variant="done" sx={{ m: 3, minWidth:5 }} onClick={() => {
          navigate("/pitch/home");
        }}>
          <Heading variant="topic_done"> <FontAwesomeIcon icon={faMicrophone}/> Pitch Builder </Heading>

          <Text color="curious_blue" my={4}>
            I want to work on my pitch.
          </Text>
        </Card>
      </Flex>

      <Footer />
    </Page>
  )
}
