import React from 'react'
import Cell from './Cell'
import RemoveRowButton from './RemoveRowButton'
import { Draggable } from 'react-beautiful-dnd'
import ReorderRowButton from './ReorderRowButton'
export default function Row({
  data,
  allowRemove,
  allowReorder,
  removeRow,
  index,
  headerOrder
}) {
  if (!allowReorder) {
    return (
      <tr>
        {headerOrder.map((headerLabel) => {
          return <Cell key={headerLabel} data={data.cells[headerLabel]} />
        })}
        {allowRemove && <RemoveRowButton onClick={removeRow} rowId={data.id} />}
      </tr>
    )
  }
  //console.log(data,)
  return (
    <Draggable draggableId={data.id} index={index}>
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ReorderRowButton rowId={data.id} />

          {headerOrder.map((headerLabel) => {
            return <Cell key={headerLabel} data={data.cells[headerLabel]} />
          })}
          {allowRemove && (
            <RemoveRowButton onClick={removeRow} rowId={data.id} />
          )}
        </tr>
      )}
    </Draggable>
  )
}
