
import {Box, Button, Flex, Text } from 'theme-ui'
import QuestionPrevButton from './QuestionPrevButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck} from "@fortawesome/free-solid-svg-icons";

import AutoToolTip from '../AutoToolTip'
export default function QuestionChooser({
                                          question,
                                          answer,
                                          setAnswer,
                                          stepNumber,
                                          context
                                        }) {
  const onClick = (value) => {
    setAnswer(stepNumber, question, value)
  }
  return (
    <Box>
      <AutoToolTip>{question.explanation}</AutoToolTip>

      <Text variant="question">{question.text}</Text>

      <Flex sx={{my:4}}>
      {question.values.map((choice) => {
        return (
          <Button sx={{m:4}}
            key={choice}
            variant={choice === answer ? 'selected' : 'primary'}
            onClick={() => {
              onClick(choice)
            }}
          >
            {choice}{" "}
            {choice === answer && <FontAwesomeIcon icon={faCheck}/>}
          </Button>
        )
      })}
      </Flex>

      <QuestionPrevButton context={context} />
    </Box>
  )
}
