import React, {useContext} from "react"
import QuestionChooser from "./QuestionChooser";

import {StepContext} from 'app/contexts/StepContext'
import QuestionText from "./QuestionText"
import QuestionDone from "./QuestionDone";

export default function QuestionInput({ question, answer, context }) {
    const { stepNumber } = useContext(StepContext);
    const { setAnswer, isDone } = useContext(context);


    if (!question) {
        return null;
    }
    if (isDone(stepNumber)) {
        return <QuestionDone />;
    }

    if (question.type === "choice") {
        return <QuestionChooser question={question}
                                answer={answer}
                                stepNumber={stepNumber}
                                setAnswer={setAnswer}
                                context={context}
        />
    } else if (question.type === "text") {
        return <QuestionText question={question}
                             answer={answer}
                             stepNumber={stepNumber}
                             setAnswer={setAnswer}
                             context={context}
        />
    }
    return <p>Sorry not supported yet!</p>

}