
import { Button } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from '@fortawesome/free-solid-svg-icons'

export default function RemoveRowButton({ onClick, rowId }) {
  return (
    <td>
      <Button
        variant="icon"
        onClick={() => {
          onClick(rowId)
        }}
        title="Delete row"
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
    </td>
  )
}
