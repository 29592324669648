
import {  Card, Flex, Heading, Text } from 'theme-ui'
import { useContext } from 'react'
import { PlannerContext } from 'app/contexts/PlannerContext'
import { StepContext } from 'app/contexts/StepContext'
import {
  AutoToolTip,
  Page,
  QuestionIndicator,
  QuestionInput,
  SectionHeader
} from 'components'

export default function Step3Page() {
  const { stepNumber } = useContext(StepContext)

  const {
    isLoaded,
    getSections,
    getCurrentAnswer,
    getSectionStatus,
    getCurrentQuestion
  } = useContext(PlannerContext)

  if (!isLoaded) {
    return null
  }

  const currentQuestion = getCurrentQuestion(stepNumber)
  const currentAnswer = getCurrentAnswer(stepNumber)
  return (
    <Page>
      <SectionHeader destination="/planner/home" />

      <Flex sx={{flexDirection: ['column', 'row', 'row']}}>
        <Card variant={'heading'}>
          <Heading variant="step_section">Step #3</Heading>
          <Heading variant="topic_empty">How will you spend and make money?</Heading>
          <Text>
            <AutoToolTip>
              You want to make more money than you spend. That means keeping expenses low and revenue high to make profit!
            </AutoToolTip>
          </Text>

          {getSections(stepNumber).map((section, index) => {
            return (
              <QuestionIndicator
                key={section.key}
                status={getSectionStatus(stepNumber, section.key)}
                question_number={index + 1}
                question={section.label}
              />
            )
          })}
        </Card>

        <Flex sx={{p:4}}>
          <QuestionInput question={currentQuestion} answer={currentAnswer} context={PlannerContext}/>
        </Flex>
      </Flex>
    </Page>
  )
}
