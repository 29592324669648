import React, { useContext } from 'react'
import Headers from './Headers'
import Rows from './Rows'
import Total from './Total'
import { SheetContext } from './SheetContext'
import AddRowButton from './AddRowButton'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

export default function MainSheet() {
  const {
    step,
    onDragEnd,
    allowAdd,
    isTotalBefore,
    addBlankRow,
    showTotal
  } = useContext(SheetContext)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`sheet-${step}`}>
        {(provided) => {
          return (
            <table ref={provided.innerRef} {...provided.droppableProps}>
              <tbody>
                {showTotal && isTotalBefore && <Total />}
                <Headers />
                <Rows />
                {allowAdd && <AddRowButton onClick={addBlankRow} />}
                {showTotal && !isTotalBefore && <Total />}
                {provided.placeholder}
              </tbody>
            </table>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
