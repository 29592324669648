
import {  Button,Flex, Heading } from 'theme-ui'

import { useNavigate } from 'react-router-dom'
export default function QuestionDone() {
  const navigate = useNavigate()

  function handleClick() {
    navigate("/planner/home");
  }

  return (
    <Flex sx={{flexDirection: 'column'}}>
      <Heading variant={'step_done'} my={4}>AWESOME!</Heading>

      <Heading my={3} >
        You're ready to move on to the next section.
      </Heading>

      <Button onClick={handleClick}>Let's Go!</Button>
    </Flex>
  )
}
