import { Fragment, useContext } from "react";
import { PitchContext } from "app/contexts/PitchContext";
import {Input, Text, Textarea} from "theme-ui";

const MadLibInput = ({ answer, name, placeHolder, hint }) => {
  const { setOpenItem, openItem, setAnswer } = useContext(PitchContext);
  if (name !== openItem) {

    return <Fragment>&nbsp;<span onClick={() => {
      setOpenItem(name);
    }
    }> {answer ? <Text variant={'mad_answer'}>{answer}</Text> : <Text variant={'mad_answer_empty'}>{placeHolder}</Text>}</span></Fragment>;
  }
  const handleChange = (event) => {
    setAnswer(name, event.target.value);
  };
  const size = answer?.length > 30 ? answer.length : 30;
  if (size < 60) {
    return <Input sx={{maxWidth: 7, mx:3}} size={size} autoFocus={true} value={answer || ""} placeholder={hint} onChange={handleChange} />;
  }
  return <Textarea sx={{ p:3, maxWidth: 7, mx:2}} cols={50} rows={5} autoFocus={true} selectionStart={31} placeholder={hint} onChange={handleChange} value={answer || ""} onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)} />;
 // TODO See if better way to move caret to end of answer when switching input to textarea
  // from https://stackoverflow.com/questions/44983286/send-cursor-to-the-end-of-input-value-in-react
};
export default MadLibInput;
