
import {  Button } from 'theme-ui'
import { useContext } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { StepContext } from 'app/contexts/StepContext'
import { useNavigate } from 'react-router-dom'

export default function QuestionPrevButton({ context }) {
  const { stepNumber } = useContext(StepContext);
  const { hasPrevQuestion, moveToPrevQuestion } = useContext(context);
  const firstquestion = { state: false };

  const navigate = useNavigate();

  function handleClick() {
    navigate("/planner/home");
  }

  if (!hasPrevQuestion(stepNumber)) {
    firstquestion.state = true
  }

  return (
    <Button
      onClick={() => {
        firstquestion.state ? handleClick() : moveToPrevQuestion(stepNumber)
      }}
      variant={'back'}
    >
      <FontAwesomeIcon icon={faArrowLeft}/> Back
    </Button>
  )
}
