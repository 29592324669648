/** @jsxImportSource theme-ui */
import {  Box, Flex } from 'theme-ui'

export default function QuestionIndicator({

  status,
  question_number,
  question
}) {
  const color = { step: 'eminence', question: 'eminence' }

  if (status === 'EMPTY') {
    color.step = 'gray'
    color.question = 'gray'
  }

  if (status === 'DONE') {
    color.step = 'sushi'
    color.question = 'sushi'
  }

  if (status === 'CURRENT') {
    color.step = 'razzmatazz'
    color.question = 'curious_blue'
  }

  return (
    <Flex my={4}  sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Box
        sx={{
          borderRadius: '9999px',
          bg: color.step,
          color: 'alabaster',
          padding: 2,
          width: 40,
          textAlign: 'center',
          fontSize: 3
        }}
      >
        {question_number}
      </Box>
      <Box mx={3} color={color.question}>
        {question}
      </Box>
    </Flex>
  )
}
