import { useContext } from "react";
import { PitchContext } from "app/contexts/PitchContext";
import { Box, Button, Flex, Text } from "theme-ui";
import AutoToolTip from "../../components/AutoToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const QuestionChooser = ({
                           question,
                           answer
                         }) => {
  const { setAnswer } = useContext(PitchContext);
  const onClick = (value) => {
    setAnswer(question.name, value);
  };

  return (
    <Box>
      <AutoToolTip>{question.explanation}</AutoToolTip>

      <Text variant="question">{question.text}</Text>

      <Flex sx={{ my: 4 }}>
        {question.values.map((choice) => {
          return (
            <Button sx={{ m: 4 }}
                    key={choice}
                    variant={choice === answer ? "selected" : "primary"}
                    onClick={() => {
                      onClick(choice);
                    }}
            >
              {choice}{" "}
              {choice === answer && <FontAwesomeIcon icon={faCheck}/>}
            </Button>
          );
        })}
      </Flex>
    </Box>
  );
};
export default QuestionChooser;
