import React, { useState, useEffect } from "react";
import step1Data from "app/data/planner/step1.json";
import step2Data from "app/data/planner/step2.json";
import step3Data from "app/data/planner/step3.json";
import step4Data from "app/data/planner/step4.json";
import step5Data from "app/data/planner/step5.json";
import { formatCurrency } from "../../components";

const PlannerContext = React.createContext();
const LS_ANSWER_KEY = "ventureLabPlannerAnswers";
const LS_ROW_KEY = "ventureLabPlannerRows";
const LS_TOTAL_KEY = "ventureLabPlannerTotals";
const PlannerContextProvider = (props) => {
    const numberOfQuestionSteps = 3;

    const [showFormula, setShowFormula] = useState(false);
    const [stepsState, setStepsState] = useState(new Map())
    const [answers, setAnswers] = useState(new Map())
    const [stepRows, setStepRows] = useState(new Map())
    const [stepTotals, setStepTotals] = useState(new Map())
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (!isLoaded) {
            const initialState = new Map()
            let questions = getQuestions(1)
            initialState.set(1, {currentQuestion: questions[0], isDone: false})
            questions = getQuestions(2)
            initialState.set(2, {currentQuestion: questions[0], isDone: false})
            questions = getQuestions(3)
            initialState.set(3, {currentQuestion: questions[0], isDone: false})
            setStepsState(initialState)

            const lsAnswers = localStorage.getItem(LS_ANSWER_KEY)
            if (lsAnswers) {
                setAnswers(new Map(JSON.parse(lsAnswers)))
            }
            const lsRows = localStorage.getItem(LS_ROW_KEY)
            if (lsRows) {

                setStepRows(new Map(JSON.parse(lsRows)))
            }
            const lsTotals = localStorage.getItem(LS_TOTAL_KEY)
            if (lsTotals) {
                setStepTotals(new Map(JSON.parse(lsTotals)))
            } else {
                setStepTotals(new Map().set(4, {
                    text: "",
                    value: 0,
                    raw: 0
                }).set(5, {
                    text: "",
                    value: 0,
                    raw: 0
                }))

            }
            setIsLoaded(true)
        }
    }, [isLoaded])
    const isDone = (stepNumber) => {
        const step = stepsState.get(stepNumber)
        return step.isDone
    }
    const wipeDone = (stepNumber) => {
        const step = stepsState.get(stepNumber)
        setStepsState(
            new Map(stepsState).set(
                stepNumber,
                Object.assign({}, step, {isDone: false})
            )
        )
    }
    const setDone = (stepNumber) => {
        const step = stepsState.get(stepNumber)
        setStepsState(
            new Map(stepsState).set(
                stepNumber,
                Object.assign({}, step, {isDone: true})
            )
        )
    }
    const getQuestions = (stepNumber) => {
        switch (stepNumber) {
            case 1:
                return step1Data.questions
            case 2:
                return step2Data.questions
            case 3:
                return step3Data.questions
            default:
                return []
        }
    }
    const getSections = (stepNumber) => {
        switch (stepNumber) {
            case 1:
                return step1Data.sections
            case 2:
                return step2Data.sections
            case 3:
                return step3Data.sections
            default:
                return []
        }
    }
    const getCurrentIndex = (stepNumber) => {
        if (!stepNumber) {
            throw new Error(`${stepNumber} wasn't defined`)
        }
        const step = stepsState.get(stepNumber)
        const questions = getQuestions(stepNumber)
        const result = questions.findIndex((question) => {
            return question.name === step.currentQuestion?.name
        })
        return result < 1 ? 0 : result
    }

    const hasNextQuestion = (stepNumber) => {
        const questions = getQuestions(stepNumber)
        const currentIndex = getCurrentIndex(stepNumber)
        return currentIndex + 1 < questions.length
    }
    const hasPrevQuestion = (stepNumber) => {
        const currentIndex = getCurrentIndex(stepNumber)

        return currentIndex > 0
    }
    const moveToPrevQuestion = (stepNumber) => {
        if (!hasPrevQuestion(stepNumber)) {
            return
        }
        const step = stepsState.get(stepNumber)
        const questions = getQuestions(stepNumber)
        const currentIndex = getCurrentIndex(stepNumber)

        setStepsState(
            new Map(stepsState).set(
                stepNumber,
                Object.assign({}, step, {
                    currentQuestion: questions[currentIndex - 1]
                })
            )
        )
    }
    const moveToNextQuestion = (stepNumber) => {
        const step = stepsState.get(stepNumber)
        const questions = getQuestions(stepNumber)
        const currentIndex = getCurrentIndex(stepNumber)
        if (!hasNextQuestion(stepNumber)) {
            return
        }
        setStepsState(
            new Map(stepsState).set(
                stepNumber,
                Object.assign({}, step, {
                    currentQuestion: questions[currentIndex + 1]
                })
            )
        )
    }
    const moveToFirstQuestion = (stepNumber) => {
        const step = stepsState.get(stepNumber)
        const questions = getQuestions(stepNumber)

        setStepsState(
            new Map(
                stepsState.set(
                    stepNumber,
                    Object.assign({}, step, {currentQuestion: questions[0]})
                )
            )
        )
    }
    const isSectionDone = (stepNumber, sectionNumber) => {
        const questions = getQuestions(stepNumber)
        const sectionQuestions = questions.filter((question) => {
            return question.section === sectionNumber
        })
        let hasUnansweredQuestion = false
        sectionQuestions.forEach((question) => {
            if (!answers.has(`${stepNumber}-${question.name}`)) {
                hasUnansweredQuestion = true
            }
        })
        return !hasUnansweredQuestion
    }
    const getCurrentQuestion = (stepNumber) => {
        const step = stepsState.get(stepNumber)
        return step?.currentQuestion
    }
    const clearAnswers = () => {

        localStorage.setItem(LS_ANSWER_KEY, null)
        localStorage.setItem(LS_ROW_KEY, null)
        localStorage.setItem(LS_TOTAL_KEY, null)
        setShowFormula(false)
        setAnswers(new Map())
        setStepsState(new Map())
        setStepRows(new Map())
        setStepTotals(new Map())
        setIsLoaded(false)
    }
    const setAnswer = (stepNumber, question, answer) => {
        const newAnswers = buildAnswers(stepNumber, question, answer, answers)
        storeAnswers(newAnswers)

        moveToNextQuestion(stepNumber)
        const questions = getQuestions(stepNumber)
        if (getCurrentIndex(stepNumber) + 1 === questions.length) {
            moveToFirstQuestion(stepNumber)
            setDone(stepNumber)
        }
    }

    const buildAnswers = (stepNumber, question, answer, answerList) => {
        const key = `${stepNumber}-${question.name}`
        return new Map(answerList).set(key, answer)
    }
    const storeAnswers = (newAnswers) => {
        //    console.log("Stroing? ", newAnswers)
        localStorage.setItem(
            LS_ANSWER_KEY,
            JSON.stringify(Array.from(newAnswers.entries()))
        )
        setAnswers(newAnswers)
    }
    const setRows = (stepNumber, rows) => {
        const newRows = new Map(stepRows.set(stepNumber, rows))
        localStorage.setItem(
            LS_ROW_KEY,
            JSON.stringify(Array.from(newRows.entries()))
        )
        setStepRows(newRows)
    }
    const setTotals = (stepNumber, totals) => {
        setShowFormula(false)
        const newTotals = new Map(stepTotals.set(stepNumber, totals))
        localStorage.setItem(
            LS_TOTAL_KEY,
            JSON.stringify(Array.from(newTotals.entries()))
        )
        setStepTotals(newTotals)
    }

    const calculateProfit = (expenseAmount, revenueAmount) => {
        let expense = expenseAmount ? expenseAmount : 0
        let revenue = revenueAmount ? revenueAmount : 0
        const total = revenue - expense
        const values = new Map()
        values.set('EXPENSES', {
            numeric: expense,
            raw: expense,
            text: formatCurrency(expense / 100)
        })
        values.set('REVENUE', {
            numeric: revenue,
            raw: revenue,
            text: formatCurrency(revenue / 100)
        })
        values.set('TOTAL', {
            numeric: total,
            raw: total,
            text: formatCurrency(total / 100)
        })
        return values
    }

    //  console.log("Step", stepsState.get(1)?.currentQuestion, stepsState.get(1)?.isDone)
    // console.log("Answers", answers)
    return (
        <PlannerContext.Provider
            value={{
                isLoaded,
                calculateProfit,
                isDone: isDone,
                wipeDone: wipeDone,
                hasNextQuestion: hasNextQuestion,
                hasPrevQuestion: hasPrevQuestion,
                moveToPrevQuestion: moveToPrevQuestion,
                getQuestions: getQuestions,
                getSections: getSections,
                setAnswer,
                getCurrentAnswer: (stepNumber) => {
                    const step = stepsState.get(stepNumber)
                    const question = step.currentQuestion
                    const key = `${stepNumber}-${question.name}`
                    return answers.get(key)
                },
                getAnswers: (stepNumber) => {
                    const answerObj = {}
                    answers.forEach((value, key) => {
                        const [step, field] = key.split('-')
                        if (step === `${stepNumber}`) {
                            answerObj[field] = value
                        }
                    })
                    return answerObj
                },
                getCurrentQuestion: getCurrentQuestion,
                getSectionStatus: (stepNumber, sectionNumber) => {
                    if (isDone(stepNumber)) {
                        return 'DONE'
                    }
                    const step = stepsState.get(stepNumber)
                    if (step.currentQuestion.section === sectionNumber) {
                        return 'CURRENT'
                    }
                    return isSectionDone(stepNumber, sectionNumber) ? 'DONE' : 'EMPTY'
                },
                clearAnswers: clearAnswers,
                setTestAnswers: () => {
                    clearAnswers()
                    setIsLoaded(false)
                    let newAnswers = new Map()
                    const questionStepList = [...Array(numberOfQuestionSteps).keys()].map((i) => i + 1)
                    questionStepList.forEach((stepNumber) => {
                        getQuestions(stepNumber).forEach((question) => {
                            //  console.log("Setting to ", stepNumber, question.testAnswer)
                            newAnswers = buildAnswers(stepNumber, question, question.testAnswer, newAnswers)
                        })
                    })


                    setRows(4, step4Data.rows)
                    setTotals(4, step4Data.total)
                    setRows(5, step5Data.rows)
                    setTotals(5, step5Data.total)
                    storeAnswers(newAnswers)

                },
                getRows: (stepNumber) => {
                    if (!isLoaded) {
                        return null
                    }
                    return stepRows.get(stepNumber)
                },
                setRows,
                getTotals: (stepNumber) => {
                    return stepTotals.get(stepNumber)
                },
                setTotals,
                showFormula,
                setShowFormula
            }}
        >
            {props.children}
        </PlannerContext.Provider>
    )
}

export {PlannerContext, PlannerContextProvider}
