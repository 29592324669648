import { Text } from 'theme-ui'
import { Fragment, useContext } from 'react'
import { SheetContext } from './SheetContext'

export default function Total() {
  const { total, totalConfig } = useContext(SheetContext)
  //  const totalAmount = totalAmount
  //  const amount = totalAmount.type === "MONEY" ? formatCurrency(totalAmount.numeric/100) : formatNumber(totalAmount.numeric)

  return (
    <Fragment>
      <tr>
        <td colSpan="4" sx={{ textAlign: 'right', pr: 3 }}>
          <Text variant={totalConfig.label}>{totalConfig.label}</Text>
        </td>
        <td
          variant={totalConfig.label}
          sx={{
            p: 2,
            borderRadius: 4,
            boxShadow: '0 0 12px rgba(0, 0, 0, 0.125)',
            bg: totalConfig.label === 'Total Expenses' ? 'razzmatazz' : 'sushi'
          }}
        >
          <Text
            sx={{
              color: 'curious_blue',
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: 5
            }}
          >
            {total.text ? total.text : '???'}
          </Text>
        </td>
      </tr>
      <tr>
        <td colSpan="5" sx={{ textAlign: 'right' }}>
          <Text sx={{ fontSize: 1, color: 'gray' }}>
            {totalConfig.description}
          </Text>
        </td>
      </tr>
    </Fragment>
  )
}
