import React from "react";

const StepContext = React.createContext();

const StepContextProvider = ({step, children}) => {

    return (
        <StepContext.Provider
            value={{
                stepNumber: step
            }}
        >
            {children}
        </StepContext.Provider>
    );
};

export {StepContext, StepContextProvider};