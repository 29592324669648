import { lighten, darken } from '@theme-ui/color'
const theme = {
  breakpoints: ['40em', '52em', '64em'],
  space: [2, 4, 8, 16, 32, 48, 64, 128, 256],
  sizes: [16, 32, 64, 128, 256, 384, 512, 768, 1024, 1440],
  radii: [6, 16, 24, 48],
  fonts: {
    body: 'Avenir, Roboto, system-ui, sans-serif',
    heading: 'Avenir, Roboto, sans-serif',
    sansSerif: 'Roboto, system-ui, sans-serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em'
  },
  colors: {
    royal_blue: '#2448CC',
    sandy_orange: '#f89a45',
    dark_slate: '#555558',
    seashell: '#f8f5f3',
    razzmatazz: '#ec1f73',
    razzmatazz_dark: '#b90040',
    sunglow: '#ffd327',
    sushi: '#9bc73d',
    sushi_light: '#e6f1ce',
    atlantis: '#91bd33',
    jewel: '#1b8c45',
    curious_blue: '#1699d8',
    curious_blue_light: lighten('curious_blue', 0.4),
    eminence: '#5a2974',
    scarlet_gum: '#461560',
    alabaster: '#fcfcfc',
    gray: '#878787',
    text: '#555558',
    background: '#fff',
    primary: '#1699d8'
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em'
    },
    explanation: {
      mt: 6,
      fontStyle: 'italic',
      color: 'dark_slate',
      display: 'block',
      fontSize: 4
    },
    heading: {
      color: 'curious_blue',
      my: 4
    },
    highlight: {
      color: darken('sunglow', 0.1)
    },
    step_section: { color: 'sushi', fontSize: 3 },
    step_empty: { color: 'sushi', my: 4, fontSize: 3 },
    step_done: { color: 'curious_blue', my: 4, fontSize: 3 },
    topic_empty: { color: 'curious_blue', my: 4, fontSize: 5, minHeight: 78 },
    topic_done: { color: 'alabaster', my: 4, fontSize: 5, minHeight: 78 },
    answer: { color: 'jewel', fontSize: 4, my: 4 },
    mad_answer: {
      color: 'jewel',
      fontSize: 4,
      my: 4,
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      lineHeight: 1.75
    },
    mad_answer_empty: {
      px: 6,
      color: 'razzmatazz',
      fontSize: 4,
      my: 4,
      borderBottomStyle: 'solid',
      borderBottomWidth: 2
    },
    mad_sentence: {
      my: 4,
      flexDirection: ['column', 'row', 'row'],
      alignItems: ['flex-start', 'center', 'center']
    },
    question: { display: 'block', color: 'curious_blue', fontSize: 4, my: 4 },
    'Total Expenses': { color: 'razzmatazz', fontWeight: 'bold', fontSize: 3 },
    'Total Revenue': { color: 'jewel', fontWeight: 'bold', fontSize: 3 },
    print_label: {
      m: 4,
      fontWeight: 500,
      fontSize: 3,
      lineHeight: 2,
      color: 'dark_slate',
      textAlign: (t) => 'left !important'
    },
    print_description: {
      m: 2,
      color: 'dark_slate',
      fontSize: 1,
      fontStyle: 'italic',
      textAlign: (t) => 'left !important'
    },
    print_answer: {
      m: 2,
      fontSize: 2,
      color: 'curious_blue',
      textAlign: (t) => 'left !important'
    }
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)'
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted'
    },
    empty: {
      bg: 'alabaster',
      m: 2,
      minWidth: 5,
      minHeight: 5,
      padding: 4,
      borderRadius: 4,
      borderWidth: 2,
      borderColor: 'curious_blue',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      '&:hover': {
        bg: 'sushi_light'
      }
    },
    done: {
      bg: 'sushi',
      m: 2,
      minWidth: 5,
      minHeight: 5,
      padding: 4,
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.125)',
      '&:hover': {
        bg: 'sushi_light',
        color: 'jewel'
      }
    },
    heading: {
      bg: 'alabaster',
      padding: 4,
      borderRadius: 4,
      borderWidth: 2,
      borderColor: 'curious_blue',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      maxWidth: 5
    },
    pitch: {
      bg: 'curious_blue_light',
      padding: 4,
      lineHeight: 2,
      borderRadius: 4,
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.125)'
    }
  },
  forms: {
    input: {
      borderWidth: 2,
      borderColor: 'transparent',
      borderRadius: 24,
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.125)',
      pl: 24,
      py: 18,
      '&:focus': {
        outline: 'none',
        borderColor: 'royal_blue'
      }
    },
    textarea: {
      borderWidth: 2,
      borderRadius: 24,
      borderColor: 'silver',
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.125)',
      '&:focus': {
        outline: 'none',
        borderColor: 'royal_blue'
      }
    }
  },
  links: {
    bold: {
      fontWeight: 'bold'
    },
    nav: {
      fontWeight: 'bold',
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        bg: 'royal_blue'
      }
    }
  },
  buttons: {
    primary: {
      color: 'background',
      borderRadius: 24,
      px: 48,
      bg: 'primary',
      '&:hover': {
        bg: 'royal_blue'
      }
    },
    secondary: {
      color: 'background',
      borderRadius: 24,
      px: 48,
      bg: 'sushi',
      fontSize: 4,
      '&:hover': {
        bg: 'jewel'
      }
    },
    next: {
      color: 'background',
      borderRadius: 24,
      px: 48,
      bg: 'sushi',
      '&:hover': {
        bg: 'jewel'
      }
    },
    back: {
      color: 'background',
      borderRadius: 24,
      px: 48,
      bg: 'razzmatazz',
      '&:hover': {
        bg: 'razzmatazz_dark'
      }
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'background',
      borderRadius: '50%',
      height: 32,
      width: 32,
      px: 2,
      mx: 2,
      fontSize: 1,
      bg: 'razzmatazz',
      '&:hover': {
        bg: 'razzmatazz_dark'
      }
    },
    selected: {
      color: 'background',
      borderRadius: 24,
      px: 48,
      bg: 'sushi'
    },
    disabled: {
      color: 'dark_slate',
      borderRadius: 24,
      px: 48,
      bg: 'gray'
    }
  },
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: 'body',
      fontWeight: 'body'
    },
    table: {
      width: '100%',
      border: 1,
      cellPadding: 0,
      cellSpacing: 0,
      borderColor: 'transparent'
    }
  }
}
export default theme
