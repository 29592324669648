import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'

export default function ReorderRowButton() {
  return (
    <td>
      <FontAwesomeIcon icon={faArrowsAltV} color="silver" />
    </td>
  )
}
