import React from 'react'
import { Flex, Text } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

export default function StartButton({title, onClick}) {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',
        color: "curious_blue",
        width: 4,
        m: 4,
        p:2,
        borderStyle: 'solid',
        borderRadius:8,
        borderWidth: 3,
        borderColor: 'alabaster',
        '&:hover': {
          borderColor: 'curious_blue'
        }}}
      onClick={onClick}
    >
      <Text sx={{ fontSize: 5, my: 2 }}>
        <FontAwesomeIcon icon={faUndo} />
      </Text>
      <Text sx={{ fontSize: 1 }}>{title}</Text>
    </Flex>
  )
}
