import React, { Fragment, useContext } from 'react'
import { SheetContext } from './SheetContext'
import Row from './Row'
export default function Rows() {
  const {
    headerOrder,
    allowReorder,
    rows,
    allowRemove,
    removeRow
  } = useContext(SheetContext)

  return (
    <Fragment>
      {rows.map((row, index) => {
        return (
          <Row
            key={row.id}
            index={index}
            removeRow={removeRow}
            allowRemove={allowRemove}
            allowReorder={allowReorder}
            data={row}
            headerOrder={headerOrder}
          />
        )
      })}
    </Fragment>
  )
}
