
import React, { useContext, useState, useEffect } from "react";
import { Input } from "theme-ui";
import { SheetContext } from "./SheetContext";
import {
  stripToNumber,
  stripNonNumeric,
  formatNumber,

  stripToCents
} from './utils'

export default function Cell({ data }) {
  const { updateRow } = useContext(SheetContext)

  switch (data.type) {
    case 'MONEY':
      return <MoneyCell data={data} updateRow={updateRow} />

    case 'NUMBER':
      return <NumberCell data={data} updateRow={updateRow} />

    case 'SUM':
      return <SumCell data={data} updateRow={updateRow} />

    default:
      return <LabelCell data={data} updateRow={updateRow} />
  }
}

function LabelCell({ data, updateRow }) {
  const onChange = (event) => {
    updateRow(
      data.rowId,
      Object.assign({}, data, {
        value: {
          raw: event.target.value,
          text: event.target.value,
          numeric: 0
        }
      })
    )
  }
  return (
    <td width="33%">
      <Input defaultValue={data.value.text} onChange={onChange} />
    </td>
  )
}

function customChange(data, updateRow, formatNumber, stripNumber, setCursor) {
  const onChange = (event) => {

    //const originalString = event.target.value
    let rawString = stripNonNumeric(`${event.target.value}`)
// console.log({
//     raw: formatNumber(rawString),
//     test: rawString,
//     numeric: stripNumber(rawString)
//
// })
    updateRow(
        data.rowId,
        Object.assign({}, data, {
          value: {
              raw: formatNumber(rawString),
              test: rawString,
              numeric: stripNumber(rawString)
          }
        })
    )
    return;


    // //   console.log("Before", data.value)
    // let rawString = stripNonNumeric(`${event.target.value}`)
    // rawString = rawString === '' ? '0' : rawString
    // let text
    // let raw = rawString
    // let numeric = data.value.numeric
    // if (rawString[rawString.length - 1] === '.') {
    //   text = rawString
    // } else {
    //   text = formatNumber(rawString)
    //   numeric = stripNumber(rawString)
    // }
    //
    // let posMod = 0
    //
    // const origSlice = originalString.slice(0, event.target.selectionStart)
    // const newSlice = text.slice(0, event.target.selectionStart)
    // //  console.log(originalString === "", " vs", rawString, origSlice, newSlice)
    // if (
    //   origSlice.replace(/,/g, '').length !== newSlice.replace(/,/g, '').length
    // ) {
    //   posMod = 1
    // } else if (origSlice.match(/\$0/)) {
    //   posMod = -1
    // } else if (newSlice === '$') {
    //   posMod = 1
    // } else if (rawString === '0') {
    //   posMod = 1
    // }
    // const caretStart = event.target.selectionStart + posMod
    // const caretEnd = event.target.selectionEnd + posMod
    // // console.log("setting to", {
    // //     text,
    // //     raw, numeric
    // // })
    // // console.log(posMod)
    // updateRow(
    //   data.rowId,
    //   Object.assign({}, data, {
    //     value: {
    //       text,
    //       raw,
    //       numeric
    //     }
    //   })
    // )
    // setCursor({ start: caretStart, end: caretEnd })
  }
  return onChange
}

function MoneyCell({ data, updateRow }) {
  // const inputRef = React.createRef()
  // const [cursor, setCursor] = useState({ start: -1, end: -1 })
  // useEffect(() => {
  //   if (cu$0.00rsor.start > -1) {
  //     inputRef.current.selectionStart = cursor.start
  //     inputRef.current.selectionEnd = cursor.end
  //   }
  // }, [data, cursor, inputRef])

  return (
    <td width="18%">

      <Input
        sx={{ textAlign: 'right' }}

        value={data.value.text}
        onChange={customChange(
          data,
          updateRow,
          (value) => value,
          stripToCents,
            () => {}
        )}
      />
    </td>
  )
}

function NumberCell({ data, updateRow }) {
  const inputRef = React.createRef()
  const [cursor, setCursor] = useState({ start: -1, end: -1 })
  useEffect(() => {
    if (cursor.start > -1) {
      inputRef.current.selectionStart = cursor.start
      inputRef.current.selectionEnd = cursor.end
    }
  }, [data, cursor, inputRef])

  return (
    <td width="15%">
      <Input
        sx={{ textAlign: 'center' }}
        ref={inputRef}
        value={data.value.text}
        onChange={customChange(
          data,
          updateRow,
          formatNumber,
          stripToNumber,
          setCursor
        )}
      />
    </td>
  )
}

function SumCell({ data, updateRow }) {
  return (
    <td>
      <Input
        readOnly
        value={data.value.text}
        sx={{ textAlign: 'right', fontWeight: 'bold' }}
      />
    </td>
  )
}
