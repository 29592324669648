import React from 'react'
import { Box } from 'theme-ui'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export default function LinkHome() {
  return (
    <Box my={4}>
      <Link style={{fontSize:22, textDecoration: 'none'}} to={"/"}> <FontAwesomeIcon icon={faHome} /> Return Home </Link>
    </Box>
  )
}
