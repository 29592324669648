import React from "react";
import SheetHeaders from "./SheetHeaders";
import SheetHeader from "./SheetHeader";
import SheetTotal from "./SheetTotal";

export function convertChildrenToConfig(children) {
    const config = new Map()
        .set("headers", [])
        .set("total", null)
    convertChildrenToHeaders(config, children)
    convertChildrenToTotal(config, children)
    return config
}

function convertChildrenToHeaders(config, children) {
    if (children) {
        let headersFound = false

        React.Children.map(children, (child) => {
            if (child.type !== SheetHeaders || headersFound) {
                return null
            }
            headersFound = true
            config.set('headers', getHeaderChildren(child.props.children))
        })
    }
}

function getHeaderChildren(children) {
    const headers = []
    React.Children.map(children, (child) => {
        if (child.type !== SheetHeader) {
            return null
        }
        let type = "LABEL"
        let source = null
        if (child.props.money) {
            type = "MONEY"
        } else if (child.props.sum) {
            type = "SUM"
            source = child.props.source
        } else if (child.props.count) {
            type = "NUMBER"
        }

        headers.push({
            label: child.props.children,
            source: source,
            type: type
        })
    })

    return (headers)
}

function convertChildrenToTotal(config, children) {
    if (children) {
        let totalFound = false

        React.Children.map(children, (child) => {
            if (child.type !== SheetTotal || totalFound) {
                return null
            }
            totalFound = true

            config.set('total', {
                source: child.props.source,
                label: child.props.label || "Total",
                description: child.props.description || "",
                location: child.props.before ? "BEFORE" : "AFTER"
            })
        })
    }
}

export function formatCurrency(value) {
    return (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
        .format(value))
}
export function formatNumber(value) {
    return new Intl.NumberFormat('en-US')
        .format(value)
}

export function stripToNumber(value) {
    return Number(stripNonNumeric(value))
}
export function stripNonNumeric(value) {
    return stripExtraNegative(stripExtraPeriods(value.replace(/[^-.0-9]/g, "")))
}
export function stripExtraPeriods(value) {
    return value.replace(/\./, "X")
        .replace(/\./g, "")
        .replace(/X/, ".")
}
export function stripExtraNegative(value) {
    const isNegative = value && value.charAt && value.charAt(0) === '-' ? true : false

    const stripped =  value.replace(/-/g, "")
 //   console.log("Value is negative", value, isNegative,value.charAt(0))
    return isNegative ? "-" + stripped : stripped

}
export function stripToCents(value) {
    let  realValue = stripToNumber(value)

   return Math.round(Number(realValue) * 100)
}
