
import {  Box, Button, Flex, Heading, Text } from 'theme-ui'
import { useContext, useState } from 'react'
import { PlannerContext } from 'app/contexts/PlannerContext'
import { AutoToolTip } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faEquals } from '@fortawesome/free-solid-svg-icons'

export default function Step6Block() {
  const {
    getTotals,
    showFormula,
    setShowFormula,
    calculateProfit
  } = useContext(PlannerContext)
  const [localNumbers, setLocalNumbers] = useState(calculateProfit())
  const [status, setStatus] = useState('NOTHING')

  const updateFormula = () => {
    setShowFormula(true)

    const newValues = calculateProfit(
      getTotals(4).numeric,
      getTotals(5).numeric
    )
    setLocalNumbers(newValues)
    if (
      newValues.get('EXPENSES').numeric === 0 &&
      newValues.get('REVENUE').numeric === 0
    ) {
      setStatus('NOTHING')
    } else if (newValues.get('TOTAL').numeric === 0) {
      setStatus('BREAK_EVEN')
    } else if (newValues.get('TOTAL').numeric > 0) {
      setStatus('PROFITABLE')
    } else if (newValues.get('TOTAL').numeric < 0) {
      setStatus('UNPROFITABLE')
    }
  }

  if (!showFormula) {
    return (
      <Flex sx={{my: 4, flexDirection: 'column'}}>
        <Heading variant="step_empty">Step #6</Heading>
        <Heading variant="topic_empty">Results</Heading>
        <Heading color="gray">You're almost done!</Heading>
        <Text my={4} color="gray">
          It's time to see if your business will be profitable (makes money) or
          unprofitable (loses money).
        </Text>
        <Button variant="secondary" onClick={updateFormula}>
          Test Your Business Plan
        </Button>
      </Flex>
    )
  }

  return (
    <Box my={4}>
      <Heading variant="step_empty">Step #6</Heading>
      <Heading variant="topic_empty">Results</Heading>
      <Status status={status} />

      <Flex sx={{alignItems:['center','flex-start','flex-start'], flexDirection: ['column','row','row'] }}>
        <Flex
          sx={{
            bg: 'sushi',
            flexDirection: 'column',
            p: 3,
            alignItems: 'center',
            borderRadius: 5
          }}
        >
          <Text sx={{ color: 'alabaster', fontSize: 6, fontWeight: 'bold' }}>
            {localNumbers.get('REVENUE').text}
          </Text>
          <Text color="alabaster">Revenue</Text>
        </Flex>
        <Flex sx={{ m: 4, fontSize: 6, alignItems: 'center' }}>
          <FontAwesomeIcon icon={faMinus} />
        </Flex>
        <Flex
          sx={{
            bg: 'razzmatazz',
            flexDirection: 'column',
            p: 3,
            alignItems: 'center',
            borderRadius: 5
          }}
        >
          <Text sx={{ color: 'alabaster', fontSize: 6, fontWeight: 'bold' }}>
            {localNumbers.get('EXPENSES').text}
          </Text>
          <Text color="alabaster">Expenses</Text>
        </Flex>
        <Flex sx={{ m: 4, fontSize: 6, alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEquals} />
        </Flex>
        <Flex
          sx={{
            bg: 'dark_slate',
            flexDirection: 'column',
            p: 3,
            alignItems: 'center',
            borderRadius: 5
          }}
        >
          <Text
            sx={{
              color:
                localNumbers.get('TOTAL').numeric >= 0 ? 'sushi' : 'razzmatazz',
              fontSize: 6,
              fontWeight: 'bold'
            }}
          >
            {localNumbers.get('TOTAL').text}
          </Text>
          <Text color="alabaster">
            {localNumbers.get('TOTAL').numeric >= 0 ? 'Profit' : 'Loss'}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

function Status({ status }) {
  if (status === 'NOTHING') {
    return (
      <Box my={4}>
        <Heading color="gray">Your business plan isn't complete yet.</Heading>
        <AutoToolTip>Continue to fill out the questions above.</AutoToolTip>
      </Box>
    )
  }
  if (status === 'PROFITABLE') {
    return (
      <Box my={4}>
        <Heading color="sushi">Your business is profitable!</Heading>
        <AutoToolTip>
          What will you do with your hard earned money? Cash out and buy that game you wanted? Donate it to charity? Reinvest in your business to offer more of your product or service? If you want to see if you can be even more profitable, make changes to your expenses, and revenue above and test your plan again.
        </AutoToolTip>
      </Box>
    )
  }
  if (status === 'BREAK_EVEN') {
    return (
      <Box my={4}>
        <Heading color="dark_slate">Your business is break even.</Heading>
        <AutoToolTip>
          You may want to look at your numbers again. There will be no room for mistakes in this business plan.
        </AutoToolTip>
      </Box>
    )
  }
  if (status === 'UNPROFITABLE') {
    return (
      <Box my={4}>
        <Heading color="razzmatazz">Your business is not profitable.</Heading>
        <AutoToolTip>
          You need to find a way to decrease your expenses or increase your revenue. You don't want to run a business that you are paying for customers to use.
        </AutoToolTip>
      </Box>
    )
  }
  return null
}
