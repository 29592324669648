import { useState, useEffect, useContext } from 'react'
import { Box, Flex, Text, Textarea } from 'theme-ui'
import QuestionPrevButton from './QuestionPrevButton'
import QuestionNextButton from './QuestionNextButton'
import AutoToolTip from '../AutoToolTip'

export default function QuestionText({
  question,
  answer,
  setAnswer,
  stepNumber,
  context
}) {
  const [localAnswer, setLocalAnswer] = useState()

  useEffect(() => {
    setLocalAnswer(answer)
  }, [question, answer])
  let disabledNext = true
  let onClick = () => {}
  if (localAnswer && localAnswer !== '') {
    disabledNext = false
    onClick = () => {
      setAnswer(stepNumber, question, localAnswer)
      setLocalAnswer('')
    }
  }
  const { getAnswers } = useContext(context)
  const step1Answers = getAnswers(1)

  const handleChange = (event) => {
    setLocalAnswer(event.target.value)
  }

  return (
    <Box>
      <AutoToolTip>
        {question.explanation.replace(
          '[ product | service ]',
          step1Answers.offerType
        )}
      </AutoToolTip>
      <Text variant="question">
        {question.text.replace('[ product | service ]', step1Answers.offerType)}
      </Text>
      <Textarea
        autoFocus={true}
        placeholder={question.placeHolder.replace(
          '[ product | service ]',
          step1Answers.offerType
        )}
        value={localAnswer}
        onChange={handleChange}
        rows={8}
        cols={60}
        sx={{ p: 4, fontSize: 3 }}
      />

      <Flex
        sx={{
          my: 4,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <QuestionPrevButton context={context} />
        <QuestionNextButton disabled={disabledNext} onClick={onClick} />
      </Flex>
    </Box>
  )
}
