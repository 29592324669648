
import {  Box, Heading, Text } from 'theme-ui'
import { PlannerContext } from 'app/contexts/PlannerContext'
import { useContext } from 'react'

import {
  Sheet,
  SheetHeaders,
  SheetHeader,
  SheetTotal,
  AutoToolTip
} from 'components'

export default function Step4Block() {
  const { getAnswers } = useContext(PlannerContext)
  const step1Answers = getAnswers(1)
  return (
    <Box sx={{minWidth: '48%',mr:4}}>
      <Heading variant="step_empty">Step #4</Heading>

      <Heading variant="topic_empty">Expenses</Heading>

      <Text sx={{my:4}}>
        <AutoToolTip>
          Anything that costs you money is an expense. The more money you spend,
          the less likely you are to make a profit. Look back at Step #3. List
          all the materials and supplies you need to purchase in order to make
          and deliver your{step1Answers.offerType || 'product'}.
        </AutoToolTip>
      </Text>

      <Sheet step={4} defaultRows={3} allowAdd allowRemove allowReorder>
        <SheetTotal
          after
          source={(rows) => {
            return {
              type: 'MONEY',
              numeric: rows.reduce((accumulator, row) => {
                return accumulator + row.cells['Total'].value.numeric
              }, 0)
            }
          }}
          label={'Total Expenses'}
          description="This is about how much you should expect to spend."
        />
        <SheetHeaders>
          <SheetHeader>Item Name</SheetHeader>
          <SheetHeader money>Cost</SheetHeader>
          <SheetHeader count>How Many?</SheetHeader>
          <SheetHeader
            sum
            source={(row) => {
              return {
                type: 'MONEY',
                numeric:
                  row.cells['Cost'].value.numeric *
                  row.cells['How Many?'].value.numeric
              }
            }}
          >
            Total
          </SheetHeader>
        </SheetHeaders>
      </Sheet>
    </Box>
  )
}
