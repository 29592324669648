import React, { useContext } from 'react'
import { SheetContext } from './SheetContext'
import Header from './Header'

export default function Headers() {
  const { headerConfig, allowReorder } = useContext(SheetContext)

  return (
    <tr>
      {allowReorder && <th>&nbsp;</th>}
      {headerConfig.map((header) => {
        return (
          <Header label={header.label} type={header.type} key={header.label} />
        )
      })}
    </tr>
  )
}
