import React from 'react'
import { Flex } from 'theme-ui'

export default function Page({ children }) {
  return (
    <Flex sx={{m: 4, flexDirection: 'column'}}>
      {children}
    </Flex>
  )
}
