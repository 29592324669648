
import {  Box, Heading, Text } from 'theme-ui'

import {
  AutoToolTip,
  Sheet,
  SheetHeader,
  SheetHeaders,
  SheetTotal,
} from 'components'

export default function Step5Block() {
  return (
    <Box sx={{minWidth: '50%'}}>
      <Heading variant="step_empty">Step #5</Heading>

      <Heading variant="topic_empty">Revenue</Heading>

      <Text my={4}>
        <AutoToolTip>
          Money that flows into your business is called revenue.  The more revenue you bring in, the more likely you are to make a profit! For each product and service, the customer pays a certain amount of money, called its price. List each product or service you offer below, its price, and estimate how many you think you will sell. Be sure to include any other sources of revenue.
        </AutoToolTip>
      </Text>
      <Sheet step={5} defaultRows={3} allowAdd allowRemove allowReorder>
        <SheetTotal
          after
          source={(rows) => {
            return {
              type: 'MONEY',
              numeric: rows.reduce((accumulator, row) => {
                return accumulator + row.cells['Total'].value.numeric
              }, 0)
            }
          }}
          label={'Total Revenue'}
          description="This is about how much money you should expect to collect."
        />
        <SheetHeaders>
          <SheetHeader>Item Name</SheetHeader>
          <SheetHeader money>Price</SheetHeader>
          <SheetHeader count>How Many?</SheetHeader>
          <SheetHeader
            sum
            source={(row) => {
              return {
                type: 'MONEY',
                numeric:
                  row.cells['Price'].value.numeric *
                  row.cells['How Many?'].value.numeric
              }
            }}
          >
            Total
          </SheetHeader>
        </SheetHeaders>
      </Sheet>
    </Box>
  )
}
