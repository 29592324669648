import React from "react";
import FrontPage from "pages/pitch/FrontPage";

import { StepContextProvider } from "app/contexts/StepContext";

import {
  Routes,
  Route
} from "react-router-dom";
import PrintPage from "./PrintPage";


function App() {
  return (

    <Routes>
      <Route path="/print" element={<PrintPage />} />
      <Route path="/home" element={<StepContextProvider step={1}><FrontPage /></StepContextProvider>} />
    </Routes>

  );
}


export default App;
